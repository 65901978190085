<template>
	<div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 老师查询
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="handle" style="display:flex;margin:10px 0;">
			<el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
				end-placeholder="结束日期" :shortcuts="shortcuts" @change="dateChange"></el-date-picker>
			<el-input placeholder="请输入用户名称" v-model="query.nick_name" style="margin-left: 5px;width:30%;">
			</el-input>
			<el-button icon="el-icon-search" @click="goSearch" style="margin-left: 5px;"></el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
			v-loading="tableLoading">
            <el-table-column prop="name" label="姓名" align="center"></el-table-column>
			<el-table-column prop="nick_name" label="用户名称" align="center"></el-table-column>
			<el-table-column label="性别" align="center">
				<template #default="scope">
					<span v-if="scope.row.sex==1">男</span>
					<span v-else-if="scope.row.sex==2">女</span>
					<span v-else>未知</span>
				</template>
			</el-table-column>
			<el-table-column prop="age" label="年龄" align="center"></el-table-column>
			<el-table-column prop="source_num" label="发布内容条数" align="center"></el-table-column>
			<el-table-column prop="fans_num" label="粉丝数" align="center"></el-table-column>
			<el-table-column prop="created_at" label="注册时间" align="center"></el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import request from '@/utils/request.js'
	import {
		reactive,
		ref
	} from 'vue'
	export default {
		setup() {
			let query = reactive({
				page: 1,
				nick_name: '',
				start_time: '',
				end_time: '',
				limit: 20
			}),
			tableLoading = ref(false),
			tableData = ref([]),
			pageTotal = ref(0),
			date = ref([]),
			shortcuts = [{
				text: '最近一周',
				value: (() => {
					const end = new Date();
					const start = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
					return [start, end]
				})()
			}, {
				text: '最近一个月',
				value: (() => {
					const end = new Date();
					const start = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
					return [start, end]
				})()
			}, {
				text: '最近三个月',
				value: (() => {
					const end = new Date();
					const start = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
					return [start, end]
				})()
			}];
			
			
			const goSearch = ()=>{
				query.page = 1
				getData()
			}
			const dateChange = (e)=> {
				if (e) {
					query.start_time = e[0].getTime() / 1000
					query.end_time = e[1].getTime() / 1000
				} else {
					query.start_time = ''
					query.end_time = ''
				}
			}
			const handlePageChange = (val) => {
				query.page = val
				getData()
			}
			const getData = () => {
				tableLoading.value = true
				request({
					url: '/admin/statistics/getTeacherList',
					method: 'post',
					data: {
						...query
					},
				}).then((res) => {
					tableLoading.value = false;
					if (res.code == 200) {
						// console.log(data)
						tableData.value = res.data.data;
						pageTotal.value = res.data.total;
					}
				}).catch(err => {
					tableLoading.value = false;
					console.log(err)
				});
			}
			getData()
			return {
				query,
				tableLoading,
				tableData,
				pageTotal,
				date,
				shortcuts,
				goSearch,
				dateChange,
				handlePageChange,
				getData
			}
		}
	}
</script>

<style>
</style>
